<template>
    <HtFormSwitch
        v-if="canEnableNewUi"
        id="new-ui"
        :value="isNewUiEnabled"
        :label="translate('Use new version of Heyteam (beta)')"
        :on-label="translate('Enabled')"
        :off-label="translate('Disabled')"
        name="new-ui"
        cypress="new-ui"
        :show-optional="false"
        @input="onToggleNewUi"
    />
</template>
<script>
import api from '@/store/api';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import { FEATURE_NEW_UI, hasFeatureEnabled } from '@/helpers/feature';
import debounce from 'lodash.debounce';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'SwitchNewUi',

    components: {
        HtFormSwitch,
    },

    computed: {
        canEnableNewUi() {
            return this.shared.session.companyUser.role === CompanyUser.ROLE_SUPERADMIN;
        },
        isNewUiEnabled() {
            return hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },

    methods: {
        onToggleNewUi: debounce(function () {
            api
                .settings
                .newUi
                .toggleFeature()
                .then((res) => {
                    this.$store.dispatch('config/registerFeature', res.data.data);
                }).catch(({ response }) => {
                    this.$Notifier('App').showError(response.data.message);
                });
        }, 1000),
    },
};
</script>
