<template>
    <div
        class="index"
        :class="{'old-ui': isOldUi || isHoldingPage}"
    >
        <header class="index-header">
            <slot name="headbar" />
        </header>
        <main class="index-content">
            <router-view />
        </main>
        <footer class="index-footer">
            <PortalTarget name="footer" />
        </footer>
        <div class="index-extra">
            <slot name="extra" />
        </div>
    </div>
</template>

<script>
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import { isHoldingPage } from '@/helpers/route';

export default {
    name: 'IndexLayout',
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        isHoldingPage() {
            return isHoldingPage(this.$route);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.index {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: color-mix(in srgb, var(--branding-color) 6%, #fff);
    .old-ui & {
        align-items: stretch;
        flex-direction: column;
        background-color: transparent;
        height: 100%;

        .index-content {
            min-height: auto;
            margin-top: 0;
            padding: 45px 15px;
            background-color: transparent;
            box-shadow: none;
            border-left: none;
            height: 100%;
        }
        .index-header {
            position: static;
            top: auto;
            z-index: 3;
        }
    }
    &-content {
        align-self: stretch;
        min-height: calc(100svh - 10px);
        width: 100%;
        min-width: 0;
        padding: 32px;
        margin-top: 10px;
        background-color: var(--fill-container);
        border-radius: var(--radius) 0 0 0;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
        border-left: 1px solid var(--border-primary);
    }
    &-header {
        position: sticky;
        top: 0;
        z-index: 10;
    }
    &-footer {
        z-index: 10; /** because of sidebar new ui menu item & checkbox in page dashboard programs */
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;
    }
}

@media (max-width: $tablet) {
    .index {
        min-height: 100%;
        height: auto;
        background-color: #FFFFFF;
        .old-ui & {
            min-height: 0;
            height: 100%;
            background-color: #ECEEEE;
            .index-content {
                margin-top: 10px;
                padding-bottom: 0;
                border-radius: 8px 0 0 0;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
            }
        }
        &-header {
            position: static;
        }
        &-content {
            margin: 0;
            padding: 16px 16px 0;
            padding-bottom: 100px;
            border-radius: 0;
            box-shadow: none;
        }
    }
}
</style>
